// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  _weatherid:'15195a6865acb20448bbab591251130a',
  bucket : 'doomimg',
  bk1 : 'AKIAUCYTNA3MN7VGOYP3',
  bk2 : 'TeHom6JkFiFOYCGCRPEvZaWqy1oH74o8trKJ/46+',
  reg : 'ap-northeast-2',
  endp1 : 'https://ltfivwky30.execute-api.us-east-2.amazonaws.com/post/writePost',
  endp2 : 'https://kmsbiu5tgk.execute-api.us-east-2.amazonaws.com/admin/adminCodeSubmit',
  endp3 : 'https://dxd246eq43.execute-api.us-east-2.amazonaws.com/default/ViewPost',
  endp4 : 'https://g33gagjl6k.execute-api.us-east-2.amazonaws.com/one/ViewOne'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
