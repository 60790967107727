/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:0672df82-ec61-4d78-8b20-ec8cb0a4505f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_xE9OoeCHP",
    "aws_user_pools_web_client_id": "4c693qj461ecql02abng9q646c",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_appsync_graphqlEndpoint": "https://jh2inoaqavenxhsest2tfcyo2e.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vozdnprbdrdcfas3anuci6c53e"
};


export default awsmobile;
